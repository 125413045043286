import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.Initialize:
      return {
        ...state,
        reviewStats: payload.reviewStats,
        reviewStatsByRating: payload.reviewStatsByRating,
      };
    default:
      return state;
  }
};
