import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchOrder:
      return {
        ...state,
        pendingOrder: payload.pendingOrder,
      };
    case ActionTypes.OrderUpdated:
      return {
        ...state,
        isOrderUpdated: payload.isOrderUpdated,
      };
    default:
      return state;
  }
};
