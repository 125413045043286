import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchReservation:
      return {
        ...state,
        reservation: payload.reservation,
      };
    case ActionTypes.UpdateReservation:
      return {
        ...state,
        isReservationUpdated: payload.isReservationUpdated,
      };
    default:
      return state;
  }
};
