import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchReviewStatus:
      return {
        ...state,
        submited: payload.flag,
        orderReview: payload.orderReview,
        orderSetup: payload.orderSetup,
      };
    default:
      return state;
  }
};
