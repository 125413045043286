import ActionTypes from './constants';

export default (state = [], { type, ...payload }) => {
  switch (type) {
    case ActionTypes.Initialize:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.FetchUserByUid:
      return {
        ...state,
        currentUser: payload.currentUser,
        userDetails: payload.userDetails,
        pathwayToOffers: payload.pathwayToOffers,
      };
    case ActionTypes.LoadCurrentOrder:
      return { ...state, currentOrder: payload.currentOrder };
    case ActionTypes.FetchedReservations:
      return { ...state, currentReservations: payload.currentReservations };
    case ActionTypes.LoadOrderSetup:
      return { ...state, orderSetup: payload.orderSetup };
    default:
      return state;
  }
};
